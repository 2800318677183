<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="outer">
          <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleMenuOpen">
              <el-menu-item index="-1">全部</el-menu-item>
              <el-menu-item index="0">淘宝店铺</el-menu-item>
              <el-menu-item index="1">京东店铺</el-menu-item>
              <!-- <el-menu-item index="2">阿里巴巴</el-menu-item> -->
              <el-menu-item index="3">抖音店铺</el-menu-item>
              <el-menu-item index="4">拼多多店铺</el-menu-item>
            </el-menu>
            <div class="menu-right">
              <el-button size="small" @click="refresh">点击刷新</el-button>
              <el-button @click="addStore" size="small" type="primary">添加店铺</el-button>
            </div>
          </div>
          <div class="table-outer">
            <el-table :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)">
              <el-table-column label="序号">
                <template slot-scope="scope"
                  ><span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span></template
                >
              </el-table-column>
              <el-table-column label="店铺">
                <template slot-scope="scope">
                  <div v-if="scope.row.band_type == '0'">淘宝/天猫</div>
                  <div v-else-if="scope.row.band_type == '1'">京东</div>
                  <div v-else-if="scope.row.band_type == '3'">拼多多</div>
                  <div v-else-if="scope.row.band_type == '4'">抖音</div>
                </template>
              </el-table-column>
              <el-table-column label="店铺名称" prop="shopName"></el-table-column>
              <el-table-column label="添加更新时间">
                <template slot-scope="scope" ><span>{{ timeConversion(scope.row.updatetime) }}</span></template >
              </el-table-column>
              <el-table-column label="到期截止时间">
                <template slot-scope="scope" ><span>{{ timeConversion(scope.row.expireTime) }}</span></template >
              </el-table-column>
              <el-table-column label="自动发货">
                <template slot-scope="scope"><el-switch v-model="scope.row.send_switch" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="handleSwitchChangeDebounce(scope.row)"></el-switch></template>
              </el-table-column>
              <el-table-column label="操作" >
                <template slot-scope="scope" >
                  <!-- <el-button size="mini" type="primary" @click="Orderran(scope.row)" v-if="datetime > scope.row.expireTime">重新绑定</el-button> -->
                  <el-button size="mini" @click="OrderDelete(scope.row)">删除绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pagination">
              <el-pagination background @current-change="currentChange" :page-size="pageSize" layout="total, prev, pager, next, jumper" :current-page="currentPage" :total="tableData.length"> </el-pagination>
            </div>
          </div>
          <el-dialog title="选择店铺绑定" :visible.sync="dialogVisible" style="min-width:45%;" @close="hiddens">
            <div class="content">
              <!-- <span>选择店铺类型：</span> -->
              <div class="stores">
                <div @click="storesItemClick(index, item)" class="stores-item" :class="{ active: storesIndex === index }" v-for="(item, index) in stores" :key="index">
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
            <div class="ding">
              <el-button type="primary" size="small" @click="getding">订购服务</el-button><br /><br />
                <div class="red">备注：① 点击要绑定的店铺  ②点击订购服务 在新打开的窗口页面购买服务 ③店铺授权绑定!</div>
            </div>
            <div style="margin-top:30px" v-show="isDisabled">
               <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false" size="small">取 消</el-button>
              <el-button type="primary" @click="handleBindConfirm" size="small">确 定授权</el-button>
            </span>
            </div>
          </el-dialog>
          <el-dialog :visible.sync="successDialogVisible" width="23%" center>
            <span class="content" >
              是否授权成功？
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="refresh(), (successDialogVisible = false) " size="small">授权失败</el-button>
              <el-button type="primary" size="small" @click="handleSuccessBind, refresh(), (successDialogVisible = false)">我已成功授权</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { getBandStore, autoChange, getBandDelete, permitBind } from '../../../network/api'
import { timeConversion } from 'utils/utils'
export default {
  inject: ['reload'],
  components: {
    AppMain
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion() {
      return timeConversion
    }
  },
  data() {
    return {
      isDisabled: false,
      activeIndex: '-1',
      dialogVisible: false,
      successDialogVisible: false,
      currentPage: 1, // 当前页
      pageSize: 10,
      stores: ['淘宝店铺', '京东店铺', '拼多多店铺', '抖音店铺'],
      storesIndex: null,
      // 0 淘宝  1京东 3 拼多多 4 抖音
      storeBindLink: [],
      tableData: [],
      allStoreList: [],
      pinStoreList: [],
      taoStoreList: [],
      jdStoreList: [],
      douYinStoreList: [],
      timer: null, // vue中防抖函数 坑！
      firstEntery: true, // 标记是否是第一次进入页面
      datetime: '',
      dingurl: [],
      ding: 'https://fuwu.taobao.com/ser/detail.htm?service_code=ts-24066',
      pddtok: '',
      jdtok: '',
      dytok: '',
      pdd: 'pdd',
      dy: 'dy',
      band_type: '',
      shop: '',
      platform: '',
      codeUrl: ''
    }
  },
  created() {
    this.datetime = Date.parse(new Date())
    this.refreshStoreList()
  },
  methods: {
    refresh() {
      this.reload()
    },
    hiddens() {
      this.storesIndex = -1
    },
    addStore() {
      // this.dialogVisible = true
      this.dialogVisible = true
    },

    storesItemClick(index, item) {
      this.storesIndex = index
      this.storesType = item
      if (index > 1) { index = index + 1 }
      permitBind(index).then((res) => {
        this.ding = res.data.service
        this.codeUrl = res.data.authUrl
      })
    },
    getding() {
      if (this.storesIndex !== null) {
        window.open(this.ding, '_blank')
        setTimeout(() => { this.isDisabled = true }, 3000)
      } else {
        return this.$message({ message: '请选择绑定的平台', type: 'warning' })
      }
    },
    currentChange(val) {
      this.currentPage = val
    },
    Orderran(row) {
      if (row.band_type === '0') {
        window.open(this.storeBindLink[0], '_blank')
      } else if (row.band_type === '1') {
        window.open(this.storeBindLink[1], '_blank')
      } else if (row.band_type === '2') {
        window.open(this.storeBindLink[3], '_blank')
      } else if (row.band_type === '3') {
        window.open(this.storeBindLink[4], '_blank')
      }
    },
    OrderDelete(row) {
      getBandDelete({ storeId: row.id }).then((res) => {
        if (res.code !== 1) return this.$message.warning(res.msg)
        this.refreshStoreList()
        this.$message({ message: res.msg, type: 'success' })
      })
    },

    handleBindConfirm() {
      this.dialogVisible = false
      this.successDialogVisible = true
      window.open(this.codeUrl, '_blank')
      // if (this.storesIndex === 0) {
      //   window.open(this.storeBindLink[0], '_blank')
      // } else if (this.storesIndex === 1) {
      //   window.open(this.storeBindLink[1], '_blank')
      // // eslint-disable-next-line eqeqeq
      // } else if (this.storesIndex === 3 && parseInt(this.$store.getters.getSiteInfo.bandtype_pdd) == 1) {
      //   window.open(this.storeBindLink[4], '_blank')
      // }
    },
    handleSuccessBind() {
      // close the dialog
      this.successDialogVisible = false
      // refresh store list
      this.refreshStoreList()
    },
    handleSwitchChangeDebounce(row) {
      this.debounce(this.handleSwitchChange, 1000)(row)
    },
    handleSwitchChange(row) {
      // console.log(row)
      autoChange({
        id: row.id,
        status: row.send_switch
      }).then((res) => {
        let type
        res.code === 1 ? (type = 'success') : (type = 'error')
        this.$message({ message: res.msg, type })
      })
    },
    // 防抖函数
    debounce(fn, delay) {
      const that = this
      return function () {
        const args = arguments
        if (that.timer) clearTimeout(that.timer)
        that.timer = setTimeout(() => {
          fn.apply(that, args)
        }, delay)
      }
    },
    refreshStoreList() {
      this.allStoreList = []
      this.taoStoreList = []
      this.jdStoreList = []
      this.pinStoreList = []
      this.douYinStoreList = []
      getBandStore().then((res) => {
        // console.log(res, ' ----=====')
        if (res.code !== 1) return
        // res.data got the store information
        for (let i = 0; i < res.data.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (Number(res.data[i].band_type) === 0) {
            this.taoStoreList.push(res.data[i])
          } else if (Number(res.data[i].band_type) === 1) {
            this.jdStoreList.push(res.data[i])
          } else if (Number(res.data[i].band_type) === 3) {
            this.pinStoreList.push(res.data[i])
          } else if (Number(res.data[i].band_type) === 4) {
            this.douYinStoreList.push(res.data[i])
          }
        }
        this.allStoreList = res.data
        this.tableData = res.data
        if (!this.firstEntery) {
          this.$message({ message: '刷新成功', type: 'success' })
        }
        // first entery the page,do not show the refresh success message.
        this.firstEntery = false
      })
    },
    // refresh () {
    //   this.debounce(this.refreshStoreList, 1000)()
    // },
    handleMenuOpen(item, index) {
      // index is string type
      switch (+index) {
        case -1:
          this.tableData = this.allStoreList
          this.shop = this.allStoreList
          break
        case 0:
          this.tableData = this.taoStoreList
          this.shop = this.taoStoreList
          break
        case 1:
          this.tableData = this.jdStoreList
          this.shop = this.jdStoreList
          break
        // case 2:
        //   // alibaba data
        //   this.tableData = []
        //   break
        case 3:
          this.tableData = this.douYinStoreList
          this.shop = this.douYinStoreList
          break
        case 4:
          this.tableData = this.pinStoreList
          break
      }
    },
    // 时间戳转化
    timestampToTime(timestamp) {
      var newtime = timestamp.slice(0, 10)
      return Date.parse(newtime)
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/storeBind.css">
</style>

<style scoped>

.outer .el-dialog__header {
  border-bottom: solid 1px #e8e8e8;
}
.dianpu {
  font-size: 20px;
  font-weight: 800;
  /* font-weight: 500; */
  /* color: #5757e9; */
  padding-right: 10px;
}
.outer .el-dialog__footer {
  border-top: solid 1px #e8e8e8;
}
</style>
